import { Api } from '@/api/api'
import { NewCityDto } from '@/models/jobBank/interfaces/locations/locations'
class CitiesApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.TALENT_API_ENDPOINT
  }

  public async searchCity(search: string): Promise<Array<any>> {
    return Api.get(`${this.baseURL}/cities?search=${search}`)
  }

  public async createCity(city: NewCityDto): Promise<any> {
    return Api.post(`${this.baseURL}/cities`, city)
  }
}
export const CitiesAPI = new CitiesApi()
