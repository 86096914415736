
import { defineComponent, reactive, ref, Ref, watch, onMounted, computed } from 'vue'
import InputText from 'primevue/inputtext'
import useVuelidate from '@vuelidate/core'
import { required, numeric, url, requiredIf } from '@vuelidate/validators'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import { JobOffersAPI } from '@/api/job-offers.api'
import { CitiesAPI } from '@/api/cities.api'
import { JobBankCountriesAPI } from '@/api/countries.api'
import { IJobOfferOption } from '@/models/jobBank/interfaces/jobOffer'
import AutoComplete from 'primevue/autocomplete'

export default defineComponent({
  emits: ['nextPage', 'prevPage', 'saveChanges'],
  props: {
    editFormOfferData: {
      type: Object
    },
    isEditing: {
      type: Boolean
    }
  },
  components: {
    InputText,
    Button,
    AutoComplete
  },
  setup (props, { emit }) {
    const maxCaracters = 750

    const getModalities = async () => {
      const modalitiesFromApi = await JobOffersAPI.getOptionsByType('Modality')
      modalities.value = modalitiesFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }
    const getAreas = async () => {
      const areasFromApi = await JobOffersAPI.getOptionsByType('Area')
      areas.value = areasFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }
    const getTypes = async () => {
      const typesFromApi = await JobOffersAPI.getOptionsByType('Type')
      types.value = typesFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }
    const getHierarchy = async () => {
      const hierarchiesFromApi = await JobOffersAPI.getOptionsByType('Hierarchy')
      hierarchies.value = hierarchiesFromApi.map(({ value, _id }: {value: string, _id: string}) => {
        return { name: value, value: _id }
      })
    }

    const modalities: Ref<IJobOfferOption[]> = ref([])
    const areas: Ref<IJobOfferOption[]> = ref([])
    const types: Ref<IJobOfferOption[]> = ref([])
    const hierarchies: Ref<IJobOfferOption[]> = ref([])
    const newArea: Ref<string> = ref('')
    const cityOnEdit = `${props.editFormOfferData?.city[0]?.name}, ${props.editFormOfferData?.city[0]?.countryName}`

    const state = reactive({
      name: props.isEditing ? props.editFormOfferData?.name : '',
      jobDescription: props.isEditing ? props.editFormOfferData?.jobDescription : '',
      maxCharacterCount: maxCaracters,
      remainingCharacters: maxCaracters - (props.editFormOfferData?.jobDescription?.length ?? 0),
      modalityId: props.isEditing ? props.editFormOfferData?.modalityId : '',
      city: props.editFormOfferData?.city[0]?._id ? { id: props.editFormOfferData?.city[0]?._id, name: cityOnEdit } : { _id: '', name: '' },
      quantityEmployees: props.isEditing ? props.editFormOfferData?.quantityEmployees : undefined,
      areaId: props.isEditing ? props.editFormOfferData?.areaId : '',
      typeCompanyId: props.isEditing ? props.editFormOfferData?.typeCompanyId : '',
      hierarchyId: props.isEditing ? props.editFormOfferData?.hierarchyId : '',
      link: props.isEditing ? props.editFormOfferData?.link : ''
    })
    interface IcountryName{
    name: string,
    code: string,
    _id: string,
    _v: number
  }
    const countryName : Ref<string | IcountryName> = ref({ name: '', code: '', _id: '', _v: 0 })
    const cityName = ref('')
    const filteredCountries = ref()

    const notRemote = ref(true)

    const remoteValueId = computed(() => {
      return modalities.value.filter(({ name }) => name === 'Remoto')[0].value
    })

    const rules = {
      name: { required },
      jobDescription: {},
      modalityId: {},
      quantityEmployees: { numeric },
      areaId: {},
      typeCompanyId: {},
      hierarchyId: { },
      link: { required, url },
      migratedFromScraper: {}
    }

    const cityLoaded = ref(false)
    watch(state, () => {
      if (!state.city.name) {
        cityLoaded.value = false
      } else {
        cityLoaded.value = true
      }
    })

    const checkLocation = () => {
      if (props.isEditing) {
        if (props.editFormOfferData?.city[0]?._id) {
          cityLoaded.value = true
        } else {
          cityLoaded.value = false
        }
      }
    }

    const submited = ref(false)
    const v$ = useVuelidate(rules, state)

    const next = async () => {
      submited.value = true
      v$.value.$touch()

      const isFormCorrect = await v$.value.$validate()
      const { name, jobDescription, modalityId, quantityEmployees, areaId, typeCompanyId, hierarchyId, link } = v$.value
      if (!isFormCorrect) {
        return
      }
      emit('nextPage', { formOfferData: { jobDescription: jobDescription.$model, name: name.$model, modalityId: modalityId.$model, cityId: state.city._id, quantityEmployees: quantityEmployees.$model, areaId: areaId.$model, typeCompanyId: typeCompanyId.$model, hierarchyId: hierarchyId.$model, link: link.$model }, pageIndex: 0 })
    }

    const back = () => { emit('prevPage', { pageIndex: 0 }) }

    const saveChanges = async () => {
      submited.value = true
      const { name, jobDescription, modalityId, quantityEmployees, areaId, typeCompanyId, hierarchyId, link, migratedFromScraper } = v$.value
      emit('saveChanges', { formOfferData: { jobDescription: jobDescription.$model, name: name.$model, modalityId: modalityId.$model, cityId: state.city._id, quantityEmployees: quantityEmployees.$model, areaId: areaId.$model, typeCompanyId: typeCompanyId.$model, hierarchyId: hierarchyId.$model, link: link.$model }, pageIndex: 0 })
    }

    const toast = useToast()

    const copyToClipboard = () => {
      navigator.clipboard.writeText(state.link as string)
      if (state.link !== '') {
        toast.add({ severity: 'success', detail: 'Los campos completados se han guardado correctamente.', life: 3500 })
      }
    }

    const displayAreaModal = ref(false)
    const openAreaModal = () => {
      displayAreaModal.value = true
    }

    const closeAreaModal = () => {
      displayAreaModal.value = false
    }

    const countries = ref()
    const getCountries = async () => {
      countries.value = await JobBankCountriesAPI.findAll()
    }

    const displayLocationModal = ref(false)

    const openLocationModal = () => {
      displayLocationModal.value = true
      getCountries()
    }

    const closeLocationModal = () => {
      displayLocationModal.value = false
    }

    const filteredCity = ref()
    const searchLocation = async (event: any) => {
      setTimeout(() => {
        const filterCity = CitiesAPI.searchCity(event.query)
        filterCity.then((filterCity) => {
          filteredCity.value = filterCity
        })
      }, 250)
    }

    const missingNewArea = ref(false)

    const newLocation = async () => {
      let countrySelected
      let isCountry = false
      if ((countryName.value as IcountryName).name) {
        countrySelected = countryName.value
        isCountry = true
      } else if (filteredCountries.value[0]) {
        countrySelected = filteredCountries.value[0]
        isCountry = filteredCountries.value[0].name.toLowerCase() === (countryName.value as string).toLowerCase()
      }
      if (isCountry && countrySelected._id && cityName.value && countrySelected.name) {
        try {
          await CitiesAPI.createCity({ name: cityName.value, countryName: countrySelected.name, countryId: countrySelected._id })
          closeLocationModal()
        } catch {
          toast.add({ severity: 'error', detail: `La ciudad ${cityName.value} ya se encuentra registrada`, life: 3500 })
        }
      }
    }

    const findNewArea = (areaName: any) => {
      return areas.value.filter(({ name }) => name === areaName)[0]
    }

    const newOfferArea = async () => {
      if (newArea.value) {
        await JobOffersAPI.createArea({ value: newArea.value })
        await getAreas()
        state.areaId = findNewArea(newArea.value).value
        closeAreaModal()
      } else {
        missingNewArea.value = true
      }
    }

    watch(state, () => {
      notRemote.value = state.modalityId !== remoteValueId.value
    })

    watch(newArea, () => {
      if (newArea.value) missingNewArea.value = false
    })
    const searchCountry = (event: any) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredCountries.value = [...countries.value]
        } else {
          filteredCountries.value = countries.value.filter((country: any) => {
            return country.name.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    }

    onMounted(async () => {
      await getAreas()
      await getModalities()
      await getTypes()
      await getHierarchy()
      checkLocation()
      notRemote.value = state.modalityId !== remoteValueId.value
    })

    const updateCounter = () => {
      state.remainingCharacters = maxCaracters - state.jobDescription.length
    }

    return {
      state,
      v$,
      next,
      modalities,
      areas,
      types,
      hierarchies,
      copyToClipboard,
      back,
      saveChanges,
      openAreaModal,
      closeAreaModal,
      displayAreaModal,
      newOfferArea,
      newArea,
      missingNewArea,
      displayLocationModal,
      openLocationModal,
      closeLocationModal,
      searchLocation,
      filteredCity,
      countryName,
      cityName,
      countries,
      searchCountry,
      filteredCountries,
      newLocation,
      notRemote,
      submited,
      cityLoaded,
      updateCounter
    }
  }
})
